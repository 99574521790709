export const managerToolkitHero = {
  title: `Restaurant Manager Toolkit`,
  subtext:
    "We see you putting in long hours to make your restaurant a success. That's why we created these resources. So you can spend less time doing busy-work and more time doing what you love. See how restaurant management software can win you back hours a day.",
  heroImg: "",
  PrimaryCta: {
    ctaTitle: "Talk to an expert who gets it",
    ctaTarget: "https://www.spoton.com/lp/manager-toolkit/demo",
  },
  video: {
    videoBtn: "Watch video",
    posterWrapperClass: "w-full -top-10 lg:top-0",
    posterImg: "manager-toolkit-hero-image.png",
    videoUrlId: "VkVCGYIJgjY",
  },
};

export const rotarySection = {
  title: "Dig in with Hillary",
  subtext: `Hillary Holmes, a 20-year veteran of the hospitality industry, knows firsthand how challenging it is to manage a restaurant. Click to watch and learn how you can overcome some of the biggest issues you’re facing today. Plus, walk through the best restaurant management tools to help you get there.`,
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "https://www.spoton.com/",
  },
  data: [
    {
      upperText: "Restaurant Manager Toolkit",
      businessName: "Staffing shortages",
      modalTitle: "Operating smoothly when staffing is tight",
      thumbnail: "staffing-shortages.png",
      image: "staffing-shortages-popup.png",
      paragraphs: [
        `Being understaffed is an issue nearly every restaurant manager faces today. It can affect your service, lead to staff burnout, and even force you to reduce your restaurant’s hours.`,
        `Hillary Holmes, industry veteran and Operator-In-Residence at SpotOn, shares 5 tips on how you can leverage technology to boost the productivity of the employees you do have (without burning them out) and simultaneously create a better guest experience.`,
      ],
      videoId: "gChsNwmzArc",
    },
    {
      upperText: "Restaurant Manager Toolkit",
      businessName: "Upgrading your POS",
      modalTitle: "6 steps to changing your POS",
      thumbnail: "upgrading-your-POS.png",
      image: "upgrading-your-POS-popup.png",
      paragraphs: [
        `More and more, restaurant managers are discovering that their old POS systems simply can’t keep up with today’s demands. But changing the technology at the heart of your restaurant technology is…well, a little frightening.`,
        `Hillary Holmes, industry vet and Operator-In-Residence at SpotOn, walks you through identifying what’s important in a new POS, and how to make sure the changeover is as seamless as possible.`,
      ],
      videoId: "QFQIWFo9n4E",
    },
    {
      upperText: `Restaurant Manager Toolkit`,
      businessName: `“Restaurant Closeout 101”`,
      modalTitle: "“Restaurant Closeout 101”",
      thumbnail: "restaurant-closeout.png",
      image: "restaurant-closeout-popup.png",
      paragraphs: [
        `What end-of-day tasks take the most time and give you the biggest headaches? Tip payouts? Batching credit card payments? Managing cash in the register?`,
        `Hillary Holmes, industry veteran and Operator-In-Residence at SpotOn, has 3 tips for running a simple, streamlined closeout, with a little help from technology.`,
      ],
      videoId: "xZQ0_5SZ_BA",
    },
  ],
  stats: null,
  className: "mt-12 md:mt-20 xl:mt-0",
};

export const largeFeature1 = {
  title: "Labor management: hiring, scheduling, payroll",
  subTitle:
    "Simplify admin and get back on the floor with these best practices and restaurant manager tools.",
  features: [
    {
      imageName: "mt-lf-1-1.png",
      title: "Playbook: Win back hours a day with labor management software",
      target:
        "https://www.spoton.com/gated-assets/save-time-on-labor-management/",
      isLarge: false,
    },
    {
      imageName: "mt-lf-1-2.png",
      title: "Blog: Handling the 80/20 tip rule",
      target: "https://spoton.com/blog/5-tips-for-restaurants-80-20-tip-rule/",
      isLarge: false,
    },
    {
      imageName: "mt-lf-1-3.png",
      title: "Template: Restaurant employee handbook",
      target:
        "https://www.spoton.com/employee-handbook-template-for-restaurants.docx",
      isLarge: false,
    },
    {
      imageName: "mt-lf-1-4.png",
      title: "Blog: Diversity & inclusion best practices",
      target:
        "https://spoton.com/blog/diversity-and-inclusion-best-practices-small-businesses/",
      isLarge: false,
    },
    {
      imageName: "mt-lf-1-5.png",
      title: "Blog: Calculating your labor-to-revenue ratio",
      target:
        "https://spoton.com/blog/how-to-minimize-the-labor-to-revenue-ratio-for-your-restaurant/",
      isLarge: true,
    },
  ],
  darkMode: true,
};

export const largeFeature2 = {
  title: "Reporting and data insights",
  subTitle:
    "Take the guesswork out of your biggest decisions. Real-time reporting can give you the insights you need to optimize everything from your menu to your operating hours and employee scheduling.",
  features: [
    {
      imageName: "mt-lf-2-1.png",
      title: "Playbook: Driving efficiency with POS Reporting",
      target:
        "https://www.spoton.com/gated-assets/driving-restaurant-efficiency-and-profits/",
      isLarge: false,
    },
    {
      imageName: "mt-lf-2-2.png",
      title: "Calculator: Break even point worksheet",
      target: "https://spoton.com/Break-Even-Point-Calculator.pdf",
      isLarge: true,
    },
    {
      imageName: "mt-lf-2-3.png",
      title: "Worksheet: Menu engineering",
      target: "https://www.spoton.com/menu-engineering-worksheet.xlsx",
      isLarge: false,
    },
    {
      imageName: "mt-lf-2-4.png",
      title: "Blog: Calculate your food cost percentage",
      target: "https://spoton.com/blog/the-food-cost-percentage-formula/",
      isLarge: false,
    },
    {
      imageName: "mt-lf-2-5.png",
      title: "Blog: Create data driven operating hours",
      target:
        "https://spoton.com/blog/5-tips-to-create-data-driven-restaurant-operating-hours/",
      isLarge: false,
    },
  ],
  darkMode: false,
};

export const rotarySection2 = {
  title: "Dive deeper with Hillary",
  subtext: `There are a ton of opportunities to save yourself time and boost operational efficiencies with restaurant management software. But where to start? Hillary Holmes leans on her 20 years of restaurant management experience and innovation to help you get started.`,
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "https://www.spoton.com/",
  },
  data: [
    {
      upperText: "Restaurant Manager Toolkit",
      businessName: "Making your menu profitable",
      modalTitle: "5 minutes to a more profitable menu",
      thumbnail: "making-your-menu-profitable.png",
      image: "making-your-menu-profitable-popup.png",
      paragraphs: [
        `With rising food costs and supply chain issues, a menu engineering strategy is critical to maintaining profitability.`,
        `Hillary Holmes, industry veteran and Operator-In-Residence at SpotOn, shows you how to use reporting from your POS to identify the stars, plow horses, puzzles, and dogs on your menu, and then fine tune it to cut costs and improve sales.`,
      ],
      videoId: "u_HWZ2YRUyA",
    },
    {
      upperText: "Restaurant Manager Toolkit",
      businessName: "Online ordering pitfalls",
      modalTitle: "4 mistakes to avoid with online ordering",
      thumbnail: "online-ordering-pitfalls.png",
      image: "online-ordering-pitfalls-popup.png",
      paragraphs: [
        `How much money are paying out to third-party online ordering and delivery services? And what about your kitchen? Is it getting swamped during peak takeout hours?`,
        `Hillary Holmes, industry veteran and Operator-In-Residence at SpotOn, discusses the four pitfalls many restaurant managers make with online ordering and how to avoid them.`,
      ],
      videoId: "GxGf6EORfvw",
    },
    {
      upperText: `Restaurant Manager Toolkit`,
      businessName: "Getting found online",
      modalTitle: "Own your online presence",
      thumbnail: "getting-found-online.png",
      image: "getting-found-online-popup.png",
      paragraphs: [
        `Too many restaurants leave their online presence to chance. Old outdated websites. Unclaimed business pages on Google. Little to no social media presence. But who has time for all that?`,
        `Hillary Holmes, industry veteran and Operator-In-Residence at SpotOn, shows how having a plan, and the right technology tools can give you the best of both worlds: a great online presence without the time and hassle.`,
      ],
      videoId: "M9UlH7xE2rQ",
    },
  ],
};

export const largeFeature3 = {
  title: "Review management & online presence",
  subTitle:
    "Learn how to make sure guests are finding you online and getting a great first impression.",
  features: [
    {
      imageName: "mt-lf-3-1.png",
      title: "Playbook: Use online reviews to drive operational improvements",
      target:
        "https://www.spoton.com/gated-assets/using-online-reviews-to-drive-operational-improvements/",
      isLarge: false,
    },
    {
      imageName: "mt-lf-3-2.png",
      title: "Blog: How to boost Yelp reviews",
      target:
        "https://www.spoton.com/blog/how-to-boost-yelp-reviews-and-increase-revenue-in-4-easy-steps/",
      isLarge: false,
    },
    {
      imageName: "mt-lf-3-3.png",
      title: "Checklist: How to manage online reviews",
      target:
        "https://www.spoton.com/chapter-2-review-management-checklist.pdf",
      isLarge: false,
    },
    {
      imageName: "mt-lf-3-4.png",
      title: "Blog: How to respond to negative reviews",
      target: "https://www.spoton.com/blog/how-to-address-negative-reviews/",
      isLarge: false,
    },
    {
      imageName: "mt-lf-3-6.png",
      title: "Blog: How to claim your business pages",
      target: "https://www.spoton.com/blog/claim-your-business-page/",
      isLarge: true,
    },
  ],
  darkMode: true,
};

export const largeFeature4 = {
  title: "POS, online ordering, waitlist, and reservations",
  subTitle:
    "Make the ordering and checkout process easier and what do you get? More table turns. Happier guests. And more productive employees (who are getting tipped better).",
  features: [
    {
      imageName: "mt-lf-4-1.png",
      title: "Checklist: Assess the health of your POS",
      target:
        "https://www.spoton.com/blog/assessing-health-restaurant-software/",
      isLarge: false,
    },
    {
      imageName: "mt-lf-4-2.png",
      title: "Blog: How to create first-party online ordering",
      target:
        "https://www.spoton.com/blog/create-online-ordering-for-restaurants/",
      isLarge: true,
    },
    {
      imageName: "mt-lf-4-3.png",
      title: "Blog: 6 ways to leverage handhelds",
      target:
        "https://www.spoton.com/blog/handhelds-transform-full-service-restaurants/",
      isLarge: false,
    },
    {
      imageName: "mt-lf-4-4.png",
      title: "Blog: QR ordering tips for success",
      target:
        "https://www.spoton.com/blog/restaurant-qr-ordering-tips-for-success/",
      isLarge: false,
    },
    {
      imageName: "mt-lf-4-5.png",
      title: "Blog: Keep tables full with digital reservations & waitlist",
      target:
        "https://www.spoton.com/blog/5-ways-reservation-software-can-enhance-your-restaurant-management/",
      isLarge: false,
    },
  ],
  darkMode: false,
};

export const largeFeature5 = {
  title: "Capital & financing for upgrades, expansion, etc",
  subTitle:
    "Whether you’re upgrading your restaurant management software, need new kitchen equipment, or are looking to expand, learn how to find the financing you need.",
  features: [
    {
      imageName: "mt-lf-5-1.png",
      title: "Playbook: Alternative financing for restaurants",
      target:
        "https://www.spoton.com/gated-assets/alternative-financing-for-restaurants/",
      isLarge: false,
    },
    {
      imageName: "mt-lf-5-2.png",
      title: "Checklist: See if you qualify for SpotOn Capital",
      target: "https://www.spoton.com/spoton-capital-checklist.pdf",
      isLarge: false,
    },
    {
      imageName: "mt-lf-5-3.png",
      title: "Blog: 5 Ways to Freshen up Your Restaurant in 2023",
      target: "https://www.spoton.com/blog/freshen-up-your-restaurant-2023/",
      isLarge: false,
    },
    {
      imageName: "mt-lf-5-4.png",
      title: "Blog: Is a merchant cash advance (MCA) safe?",
      target: "https://www.spoton.com/blog/merchant-cash-advance/",
      isLarge: false,
    },
    {
      imageName: "mt-lf-5-5.png",
      title: "Calculator: How much can upgrading tech boost your bottom line?",
      target: "https://www.spoton.com/profit-margin-calculator/",
      isLarge: true,
    },
  ],
  darkMode: true,
};

export const managerToolkitArticles = {
  title: "More inspiration & advice from Heart & Hustle",
  bgText: "Articles",
};

export const footer = {
  title: "Book your consultation",
  subtext:
    "Talk to one of our restaurant technology experts to explore how our solutions can help you better manage your restaurant—your way.",
  label: "ONE PARTNER.",
  ctaPrimary: {
    title: "Learn more",
    target: "https://www.spoton.com/lp/manager-toolkit/demo",
  },
};
