import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import Hero from "../../../components/Hero/hero";
import FeaturesWithThumbnails from "../../../components-v2/FeaturesWithThumbnails";

import {
  managerToolkitHero,
  rotarySection,
  largeFeature1,
  largeFeature2,
  rotarySection2,
  largeFeature3,
  largeFeature4,
  largeFeature5,
  managerToolkitArticles,
  footer,
} from "../../../data/landing/manager-toolkit-data";
import ogImage from "../../../images/global_assets/og-image.png";

const RotarySection = loadable(() =>
  import("../../../components-v2/RotarySection")
);
const Articles = loadable(() =>
  import("../../../components/Articles/Articles")
);
const FooterHero = loadable(() =>
  import("../../../components-v2/Sections/Hero/FooterHero")
);

const RestaurantPop = () => {
  return (
    <Layout
      className="non-fixed-width"
      isLanding
      useNewLPHeader
      phoneNumber="+18882246506"
      phoneText="(888) 224-6506"
    >
      <SEO
        title="Restaurant Manager Toolkit | Restaurant Management Software | SpotOn"
        description=" Restaurant manager tools to boost efficiency and profits. Restaurant management software, data insights, affordable loan options, employee management tools. Get started."
        image={`https://spoton.com/${ogImage}`}
      />

      <div className="mtk flex flex-row justify-center max-w-[1440px] mx-auto">
        <Hero
          className="mx-auto"
          sectionData={managerToolkitHero}
          circleBg={false}
        />
      </div>

      <RotarySection sectionData={rotarySection} animations={false} />

      <FeaturesWithThumbnails className="mt-20 lg:mt-36" {...largeFeature1} />
      <FeaturesWithThumbnails className="mt-20 lg:mt-36" {...largeFeature2} />

      <RotarySection sectionData={rotarySection2} animations={false} />

      <FeaturesWithThumbnails className="mt-20 lg:mt-36" {...largeFeature3} />
      <FeaturesWithThumbnails className="mt-20 lg:mt-36" {...largeFeature4} />
      <FeaturesWithThumbnails className="mt-20 lg:mt-36" {...largeFeature5} />

      <Articles
        className="mt-20 lg:mt-36"
        sectionData={managerToolkitArticles}
        blogFilters={{
          limit: 3,
          include: "tags,authors",
          filter: "tag:restaurant-manager-toolkit",
        }}
        cornerStone={false}
      />

      <FooterHero
        className="mt-20 lg:mt-36 mb-12 lg:mb-20"
        {...footer}
        variant={2}
      />
      <style jsx global>
        {`
          @media only screen and (max-width: 767px) {
            .mtk div.video-section__player {
              top: 16px;
            }
          }
          @media only screen and (min-width: 768px) {
            .mtk .hero__featured {
              display: flex;
              justify-content: center;
              width: 100%;
            }
            .mtk .hero__featured div.video-section__player {
              top: 16px;
              min-width: 95vw !important;
              left: 0;
              margin-left: 20px;
              margin-right: 20px;
            }
          }
          @media only screen and (min-width: 1024px) {
            .mtk .hero__featured {
              display: block;
            }
            .mtk .hero__featured div.video-section__player {
              min-width: 500px !important;
              position: absolute;
              top: 10%;
              margin: auto;
              margin-left: -20px;
              z-index: 99;
              max-width: 550px !important;
              max-height: 350px;
              min-height: 350px;
            }
          }
        `}
      </style>
    </Layout>
  );
};

export default RestaurantPop;
